import React from "react";
import AppRoutes from "./routes/AppRoutes";
import { UserProvider } from "./contexts/UserContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { RoutesProvider } from "./contexts/RoutesContext";
import { BrowserRouter as Router } from "react-router-dom";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { NotificationProvider } from "./contexts/NotificationContext";

const App = () => {
  const production = process.env.REACT_APP_AMBIENTE === 'PRODUCTION';
  if(production){
    console.log = function (...args) {}
    console.error = function (...args) {}
  }
  return (
    <Router>
      <LanguageProvider>
        <NotificationProvider>
          <RoutesProvider>
            <UserProvider>
              <WebSocketProvider>
                <AppRoutes />
              </WebSocketProvider>
            </UserProvider>
          </RoutesProvider>
        </NotificationProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
