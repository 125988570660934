import React, { createContext, useState, useEffect } from 'react';
import Api from '../Api';

// Cria um contexto para o gerenciamento de idiomas
const LanguageContext = createContext();

/**
 * Componente LanguageProvider para gerenciar o idioma da aplicação.
 *
 * Este provedor é responsável por buscar e fornecer informações sobre os idiomas
 * disponíveis, bem como permitir a troca de idioma na aplicação.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos a serem renderizados dentro do provedor.
 *
 * @returns {React.ReactElement} O componente LanguageProvider.
 *
 * @example
 * // Exemplo de uso:
 * <LanguageProvider>
 *   <SeuComponente />
 * </LanguageProvider>
 */
const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'PT_BR'); // Estado para o idioma atual
    const [languages, setLanguages] = useState([]); // Estado para os idiomas disponíveis

    useEffect(() => {
        /**
         * Função assíncrona para buscar os idiomas disponíveis da API.
         */
        const fetchLanguages = async () => {
            try {
                const response = await Api.get('/languages'); // Faz a requisição para obter os idiomas
                setLanguages(response.data); // Atualiza o estado com os idiomas recebidos
            } catch (error) {
                // Manipulação de erros pode ser adicionada aqui
            }
        };

        fetchLanguages(); // Chama a função para buscar os idiomas
    }, []);

    /**
     * Função para alternar o idioma da aplicação.
     * 
     * @param {string} lang - O código do idioma a ser definido.
     */
    const switchLanguage = (lang) => {
        setLanguage(lang); // Atualiza o estado com o novo idioma
        localStorage.setItem('language', lang); // Armazena o idioma no localStorage
    };

    /**
     * Função para traduzir um texto.
     * 
     * Esta função serve como um espaço reservado para a lógica de internacionalização futura.
     *
     * @param {string} text - O texto a ser traduzido.
     * @returns {string} O texto original (atualmente sem tradução).
     */
    const txt = (text) => {
        return text; // Retorna o texto original (sem tradução)
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language'); // Verifica se há um idioma salvo
        if (savedLanguage) {
            setLanguage(savedLanguage); // Define o idioma salvo como o idioma atual
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ language, switchLanguage, languages, txt }}>
            {children} {/* Renderiza os filhos do provedor */}
        </LanguageContext.Provider>
    );
}

export { LanguageContext, LanguageProvider };
