import { createContext, useContext, useState, useEffect } from 'react';
import NotificationDisplay from '../components/layouts/NotificationDisplay';

// Criação do contexto de notificação
export const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Função para salvar notificações no localStorage
  const saveNotificationsToLocalStorage = (notifications) => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
  };

  // Função para carregar notificações do localStorage
  const loadNotificationsFromLocalStorage = () => {
    const storedNotifications = localStorage.getItem('notifications');
    if (storedNotifications) {
      setNotifications(JSON.parse(storedNotifications));
    }
  };

  // Adicionar uma notificação
  const addNotification = (messageOrOptions, type = 'info', duration = 5) => {
    let message, typeToUse, durationToUse;

    // Verifica se o primeiro argumento é um objeto
    if (typeof messageOrOptions === 'object') {
      message = messageOrOptions.message;
      typeToUse = messageOrOptions.type || 'info';
      durationToUse = messageOrOptions.duration || 5;
    } else {
      // Se não for um objeto, assume que os parâmetros foram passados de forma posicional
      message = messageOrOptions;
      typeToUse = type;
      durationToUse = duration;
    }

    const id = Date.now();
    const newNotification = { id, message, type: typeToUse, duration: durationToUse };
    const updatedNotifications = [...notifications, newNotification];

    // Verifica se updatedNotifications não está vazio e se é diferente das notificações atuais
    if (updatedNotifications.length > 0) {
      setNotifications(updatedNotifications);
      saveNotificationsToLocalStorage(updatedNotifications); // Salva no localStorage
    }
  };

  // Remover notificação
  const removeNotification = (id) => {
    const updatedNotifications = notifications.filter((notification) => notification.id !== id);
    setNotifications(updatedNotifications);
    saveNotificationsToLocalStorage(updatedNotifications); // Atualiza o localStorage
  };

  // Carrega notificações do localStorage na inicialização
  useEffect(() => {
    loadNotificationsFromLocalStorage();
  }, []);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <NotificationDisplay notifications={notifications} removeItem={removeNotification} />
    </NotificationContext.Provider>
  );
};
