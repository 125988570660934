import React, { useEffect } from "react";
import style from "./css/NotificationDisplay.module.css"; // Arquivo CSS para os estilos

const NotificationDisplay = ({ notifications, removeItem }) => {
  // Usa o useEffect para garantir que o timeout seja aplicado corretamente para cada notificação
  useEffect(() => {
    notifications.forEach(({ id, duration }) => {
      const timer = setTimeout(() => {
        removeItem(id);
      }, duration * 1000);

      // Cleanup do timer caso a notificação seja removida antes do tempo
      return () => clearTimeout(timer);
    });
  }, [notifications, removeItem]); // Adiciona dependências para monitorar mudanças

  return (
    <div className={style.notification_container}>
      {notifications.map(({ id, message, type }) => (
        <div key={id} className={`${style.notification} ${style[type]}`}>
          <button onClick={() => removeItem(id)} className={style.close}>
            X
          </button>
          {message}
        </div>
      ))}
    </div>
  );
};

export default NotificationDisplay;
