import React, { createContext, useState, useContext, useEffect } from "react";
import Api from "../Api";
import { useNavigate } from "react-router-dom";
import { RoutesContext } from "./RoutesContext";
import Loading from "../components/layouts/Loading";
import { encryptData, decryptData } from "../utils/Cryptography";
import { LanguageContext } from "./LanguageContext";

const UserContext = createContext({});

/**
 * Componente UserProvider que envolve os filhos com a funcionalidade do contexto do usuário.
 * @param {object} children - Os componentes filhos.
 * @returns {JSX.Element} - O provider com o valor do contexto.
 */
const UserProvider = ({ children }) => {
  /**
   * Limpa o usuário e o loginTime do localStorage.
   * @returns {boolean} - Retorna true após limpar o localStorage.
   */
  const clearLocalStorage = () => {
    ["user", "loginTime"].forEach((item) => localStorage.removeItem(item));
    return true;
  };

  /**
   * Verifica se o usuário está inativo com base no tempo do último login.
   * @param {string} lastLoginTime - Tempo do último login.
   * @returns {boolean} - Retorna true se o usuário estiver inativo por mais de 1 hora.
   */
  const isInactive = (lastLoginTime) => {
    const timeDifference = Date.now() - new Date(lastLoginTime).getTime();
    return timeDifference > 1 * 60 * 60 * 1000; // 1 hora
  };

  /**
   * Agendar verificação de inatividade com base no tempo fornecido.
   * @param {number} timeToCheck - Tempo em milissegundos para a verificação ocorrer.
   */
  const scheduleInactivityCheck = (timeToCheck) => {
    const delay = timeToCheck - Date.now();

    if (delay > 0) {
      setTimeout(() => {
        const storedEncryptedTime = localStorage.getItem("loginTime");
        if (storedEncryptedTime) {
          const decryptedTime = decryptData(storedEncryptedTime);
          if (isInactive(decryptedTime)) {
            clearLocalStorage();
            window.location.reload();
          } else {
            // Agendar a próxima verificação para 1 hora depois
            const nextCheckTime =
              new Date(decryptedTime).getTime() + 1 * 60 * 60 * 1000;
            scheduleInactivityCheck(nextCheckTime);
          }
        }
      }, delay);
    }
  };

  /**
   * Atualiza o usuário e o tempo de login no localStorage.
   * @param {object} user - Objeto do usuário.
   * @param {string} currentTime - Tempo atual em formato ISO.
   */
  const updateLocalStorage = (user, currentTime) => {
    localStorage.setItem("loginTime", encryptData(currentTime));
    localStorage.setItem("user", encryptData(JSON.stringify(user)));
  };

  /**
   * Inicializa o usuário a partir do localStorage, lidando com inatividade e agendamento de verificações.
   * @returns {object} - O objeto usuário ou um objeto vazio se inativo.
   */
  const initializeUser = () => {
    const storedEncryptedUser = localStorage.getItem("user");
    const storedEncryptedTime = localStorage.getItem("loginTime");

    if (storedEncryptedUser && storedEncryptedTime) {
      const decryptedUser = decryptData(storedEncryptedUser);
      const decryptedTime = decryptData(storedEncryptedTime);

      if (isInactive(decryptedTime)) {
        clearLocalStorage();
        navigate(getPathRoute("login"));
        return {};
      }

      const currentTime = new Date().toISOString();
      updateLocalStorage(decryptedUser, currentTime);
      scheduleInactivityCheck(Date.now() + 1 * 60 * 60 * 1000);

      return { ...decryptedUser, lastLogin: currentTime };
    }

    return {};
  };

  const { txt } = useContext(LanguageContext);
  const { getPathRoute, fetchRoutes,updateRoutes } = useContext(RoutesContext);
  const navigate = useNavigate();

  // Gerenciamento de estado
  const [user, setUser] = useState(initializeUser);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cash, setCash] = useState("0,00");

  /**
   * Atualiza o tempo de login no localStorage.
   */
  const updateLoginTime = () => {
    const currentTime = new Date().toISOString();
    localStorage.setItem("loginTime", encryptData(currentTime));
  };

  /**
   * Busca o valor do caixa do usuário na API.
   */
  const fetchCash = async () => {
    setIsLoading(true);
    try {
      const { data } = await Api.get(`/cash_value/${user.id}`);
      setCash(data.cash);
    } catch (error) {
      setCash(0);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Autentica o usuário e armazena o token/usuário no localStorage.
   * @param {string} token - Token de autenticação.
   * @param {object} user - Objeto do usuário.
   */
  const auth = async (token, user) => {
    const currentTime = new Date().toISOString();
    user.token = token;
    setUser({ ...user, lastLogin: currentTime });
    updateLocalStorage(user, currentTime);
    scheduleInactivityCheck(Date.now() + 1 * 60 * 60 * 1000);
    setError("");

    const updatedRoutes = await fetchRoutes();
    updateRoutes(updatedRoutes);
  };

  /**
   * Lida com erros com base nos códigos de status e fornece mensagens de erro localizadas.
   * @param {number} status - Código de status HTTP.
   * @param {object|null} defaultMsg - Objeto de mensagem de erro padrão.
   */
  const handleError = (status, defaultMsg = null) => {
    let errorTitle = defaultMsg?.title || "Ocorreu um erro.";
    let errorMessage = defaultMsg?.error || "Tente novamente mais tarde.";

    if (status === 401) {
      errorTitle = defaultMsg?.title || "E-mail ou senha incorretos.";
      errorMessage = defaultMsg?.error || "Por favor, tente novamente.";
    }

    setError(`<h3>${txt(errorTitle)}</h3>${txt(errorMessage)}`);
  };

  /**
   * Realiza o login do usuário enviando uma requisição para a API.
   * @param {object} data - Dados de login.
   */
  const login = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.post("/login", data);
      const { status, data: responseData } = response;
      const { token, user } = responseData;

      if (status >= 200 && status < 300) {
        await auth(token, user);
        const updatedRoutes = await fetchRoutes();
        updateRoutes(updatedRoutes);
        navigate(getPathRoute(user.cash === 0 ? "deposit" : "dashboard",updatedRoutes));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Realiza o logout do usuário, limpa o localStorage e redireciona para o login.
   */
  const logout = async () => {
    setIsLoading(true);
    try {
      // Envia requisição ao Laravel para invalidar o token
      await Api.post("/logout");
    } catch (error) {
      // Opcional: lidar com erro ao tentar invalidar o token
      console.error("Erro ao tentar invalidar o token:", error);
    } finally {
      const newsRoutes = await fetchRoutes();
      setUser(null);
      clearLocalStorage();
      navigate(getPathRoute("login",newsRoutes));
      setIsLoading(false);
    }
  };

  /**
   * Realiza o registro de um novo usuário enviando uma requisição para a API.
   * @param {object} data - Dados de registro.
   */
  const register = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.post("/register", data);
      const { status, data: responseData } = response;
      const { token, user, profile } = responseData;
      user.profile = profile;

      if (status >= 200 && status < 300) {
        await auth(token, user);
        
        const updatedRoutes = await fetchRoutes();
        updateRoutes(updatedRoutes);
        navigate(getPathRoute("deposit",updatedRoutes));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Completa o registro atualizando o perfil do usuário.
   * @param {object} data - Dados de complemento do perfil.
   */
  const completeRegister = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.post("/complete_register", data);
      const { status, data: responseData } = response;
      const { token, user, profile } = responseData;
      user.profile = profile;

      if (status >= 200 && status < 300) {
        await auth(token, user);
        const updatedRoutes = await fetchRoutes();
        updateRoutes(updatedRoutes);
        navigate(getPathRoute("deposit",updatedRoutes));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
      navigate(getPathRoute("register"));
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Verifica se o usuário está logado.
   * @returns {boolean} - Retorna true se o usuário estiver logado, false caso contrário.
   */
  const isLogged = () => {
    return !!user?.id;
  };

  // Busca o valor do caixa do usuário ao mudar o usuário
  useEffect(() => {
    if (user?.id) {
      fetchCash();
    }
  }, [user]);


  return (
    <UserContext.Provider
      value={{
        user,
        error,
        cash,
        isLoading,
        login,
        register,
        completeRegister,
        logout,
        isLogged,
        auth,
        updateLoginTime,
        setCash,
      }}
    >
      {isLoading && <Loading />}
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
