import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import useAuth from "../components/hooks/useAuth";
import { decryptData, encryptData } from "../utils/Cryptography";

// Cria um contexto para o WebSocket
const WebSocketContext = createContext(null);

/**
 * Ativa ou desativa o log de depuração para conexões WebSocket.
 * @type {boolean}
 */
const debug = process.env.REACT_APP_DEBUG_WSS === "true";

/**
 * Registra uma mensagem no console se a depuração estiver ativada.
 * @param {string[]} messages - As mensagens a serem registradas.
 */
const log = (...messages) => {
  if (debug) {
    console.log(...messages);
  }
};

/**
 * Componente WebSocketProvider para gerenciar conexões WebSocket.
 *
 * Este provedor gerencia a conexão com um servidor WebSocket, lida
 * com mensagens recebidas e permite que os componentes se inscrevam em eventos.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos a serem renderizados dentro do provedor.
 *
 * @returns {React.ReactElement} O componente WebSocketProvider.
 */
export const WebSocketProvider = ({ children }) => {
  const { user } = useAuth();
  const [ws, setWs] = useState(null); // Instância do WebSocket
  const [isConnected, setIsConnected] = useState(false); // Status da conexão
  const token = user?.token || null; // Token para autenticação do WebSocket, se disponível
  const callbacks = useRef({}); // Armazena callbacks de eventos

  /**
   * Conecta ao servidor WebSocket e configura os manipuladores de eventos.
   */
  const connectWebSocket = () => {
    log("Tentando conectar ao WebSocket...");

    try {
      const wsInstance = new WebSocket(process.env.REACT_APP_WS, token || null);

      wsInstance.onopen = () => {
        log("WebSocket conectado com sucesso.");
        setIsConnected(true);
      };

      wsInstance.onclose = (event) => {
        log("WebSocket foi fechado. Razão:", event.reason);
        setIsConnected(false);
        setTimeout(() => {
          log("Reconectando ao WebSocket...");
          connectWebSocket();
        }, 10000); // Tenta reconectar após 10 segundos
      };

      wsInstance.onerror = (error) => {
        log("Erro no WebSocket:", error);
        wsInstance.close();
      };

      wsInstance.onmessage = (message) => {
        const parsedMessage = decryptData(message.data);
        log("Mensagem recebida:", parsedMessage);
        const { channel, event, data } = parsedMessage;

        log(
          "Lista de Canais, eventos e Callbacks existentes: ",
          callbacks.current
        );
        // Chama os callbacks registrados para a mensagem recebida
        if (callbacks.current[channel] && callbacks.current[channel][event]) {
          callbacks.current[channel][event].forEach(({ id, callback }) => {
            callback(data);
          });
        }
      };

      setWs(wsInstance);
    } catch (error) {
      log("Erro ao tentar conectar ao WebSocket:", error);
    }
  };

  useEffect(() => {
    log("Iniciando conexão ao WebSocket.");
    connectWebSocket();

    // Função de limpeza para fechar a conexão WebSocket ao desmontar o componente
    return () => {
      if (ws) {
        log("Fechando conexão WebSocket...");
        ws.close();
      }
    };
  }, [user?.token]); // Conecta ao WebSocket uma vez na montagem do componente

  /**
   * Inscreve um callback a um evento específico em um canal especificado.
   *
   * @param {string} channel - O canal para se inscrever.
   * @param {string} event - O evento a ser escutado.
   * @param {function} callback - O callback a ser invocado quando o evento ocorrer.
   */
  const subscribeToEvent = (channel, event, callback) => {
    if (channel.includes("undefined") || event.includes("undefined")) {
      log(
        `Canal (${channel}) ou evento (${event}) com valor 'undefined'. Assinatura ignorada.`
      );
      return;
    }

    if (!callbacks.current[channel]) {
      callbacks.current[channel] = {};
    }

    if (!callbacks.current[channel][event]) {
      callbacks.current[channel][event] = [];
    }

    const callbackId = callback.toString();

    const callbackExists = callbacks.current[channel][event].some(
      (cbObj) => cbObj.id === callbackId
    );

    if (!callbackExists) {
      log(`Assinando evento: ${event} no canal: ${channel}`, callbacks.current);
      callbacks.current[channel][event].push({ id: callbackId, callback });
    }
  };

  /**
   * Envia dados ao servidor WebSocket e escuta a resposta.
   *
   * Tenta reenviar a mensagem a cada 10 segundos se o WebSocket não estiver conectado.
   * @param {string} channel - O canal para onde os dados serão enviados.
   * @param {string} event - O evento a ser disparado.
   * @param {any} data - Os dados a serem enviados.
   * @param {number} retryCount - O número de tentativas de reenvio (padrão é 0).
   * @returns {Promise<any>} - Uma Promise que resolve com a resposta do servidor.
   */
  const sendMessage = (channel, event, data, handleResponse = null) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = { channel, event, data };
      const encryptedMessage = encryptData(JSON.stringify(message)); // Encripta a mensagem
      ws.send(encryptedMessage);
      if (handleResponse) {
        subscribeToEvent(channel, event, handleResponse);
      }
    } else {
      console.error("WebSocket não está conectado. Mensagem não enviada.");
    }
  };

 

  return (
    <WebSocketContext.Provider
      value={{ subscribeToEvent, isConnected, sendMessage }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

/**
 * Hook personalizado para usar o contexto do WebSocket.
 *
 * Este hook fornece acesso à função de assinatura do WebSocket e ao status da conexão.
 *
 * @returns {Object} Um objeto contendo a função subscribeToEvent e o status isConnected.
 *
 * @example
 * const { subscribeToEvent, isConnected } = useWebSocket();
 */
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
